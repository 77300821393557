/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, useRef, useState, useContext } from 'react'
import { CampaignContext } from '../../store'

const JSObject = ({ data }) => {
  // console.log('🚀 ~ file: JSObject.js:6 ~ JSObject ~ data:', data)
  const { src, type } = data.json
  const scriptRef = useRef(null)
  const [status, setStatus] = useState(src ? 'loading' : 'idle')
  const slug = useContext(CampaignContext)
  console.log('🚀 ~ file: JSObject.js:12 ~ JSObject ~ slug:', slug)

  // const status = useScript(src, scriptRef)

  // Add state to keep track of selected option value
  const [selectedOption, setSelectedOption] = useState('')

  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value)
  }

  useEffect(() => {
    if (selectedOption === '1' || selectedOption === '2' || slug === 'summer-leaders-experience') {
      let script = document.querySelector(`script[src="${src}"]`)
      if (!script) {
        // Create script
        script = document.createElement('script')
        script.src = src
        script.async = true
        script.setAttribute('data-status', 'loading')
        // Add script to document body or container element
        if (scriptRef) {
          scriptRef.current.appendChild(script)
        } else {
          document.body.appendChild(script)
        }
        // Store status in attribute on script
        // This can be read by other instances of this hook
        const setAttributeFromEvent = (event) => {
          script.setAttribute('data-status', event.type === 'load' ? 'ready' : 'error')
        }
        script.addEventListener('load', setAttributeFromEvent)
        script.addEventListener('error', setAttributeFromEvent)
      } else {
        // Grab existing script status from attribute and set to state.
        setStatus(script.getAttribute('data-status'))
      }
      // Script event handler to update status in state
      // Note: Even if the script already exists we still need to add
      // event handlers to update the state for *this* hook instance.
      const setStateFromEvent = (event) => {
        setStatus(event.type === 'load' ? 'ready' : 'error')
      }
      // Add event listeners
      script.addEventListener('load', setStateFromEvent)
      script.addEventListener('error', setStateFromEvent)
      // Remove event listeners on cleanup
      return () => {
        if (script) {
          script.removeEventListener('load', setStateFromEvent)
          script.removeEventListener('error', setStateFromEvent)
        }
      }
    }
  }, [selectedOption, src])

  if (slug === 'summer-leaders-experience') {
    return (
      <div id="connect" className={type}>
        <div className="acquia-form">
          <div>
            <h3>High School Juniors & Sophomores</h3>
            <p>
              Take the First Step Towards the Summer Leaders Experience:{' '}
              <strong>Connect with West Point</strong>
            </p>
            <div ref={scriptRef} id="mauticform" />
          </div>
        </div>
      </div>
    )
  }
  if (type === 'acquia') {
    return (
      <div id="connect" className={type}>
        {selectedOption === '' && (
          <div className="questionFilter">
            <h3>
              Take the First Step on Your Path to Success: <strong>Connect with West Point</strong>
            </h3>
            <div className="form-group">
              <label htmlFor="form-filter">Tell us about yourself:</label>
              <select
                className="form-control"
                id="form-filter"
                name="form-filter"
                value={selectedOption}
                onChange={handleSelectChange}
              >
                <option value="">Choose your status</option>
                <option value="1">HS Graduation: 2028</option>
                <option value="2">HS Graduation: 2025, 2026, or 2027</option>
                <option value="3">HS Graduation: 2024 or Have Graduated</option>
                <option value="4">I am in the Military</option>
              </select>
            </div>
          </div>
        )}
        <div className="acquia-form">
          {selectedOption === '1' && (
            <div>
              <h3>We’re glad you are interested!</h3>
              <p>
                While you are too young right now, your time is on the horizon. If you would like to receive
                communication from West Point, please fill out our form. We will be in touch when you reach your
                sophomore year.
              </p>
              <div ref={scriptRef} id="mauticform" />
            </div>
          )}
          {selectedOption === '2' && (
            <div>
              {' '}
              <h3>Want to connect?</h3>
              <p>
                Take the First Step on Your Path to Success: <strong>Connect with West Point</strong>
              </p>
              <div ref={scriptRef} id="mauticform" />
            </div>
          )}
          {selectedOption === '3' && (
            <div>
              <h3>Already a High School Senior or Have Graduated</h3>
              <p>
                Our application process begins <strong>February 1 of your junior year</strong> and is ideally completed
                early in the summer leading into your senior year. If you are determined to attend West Point, you do
                have the option of applying next February 2024 for the incoming class that begins July 2025. Please be
                mindful, entrants cannot be 23 or older on July 1, 2025.
              </p>
              <a
                href="https://www.westpoint.edu/admissions/steps-
to-admission"
                target="_blank"
                rel="noreferrer"
              >
                See our admissions process on our website
              </a>
            </div>
          )}
          {selectedOption === '4' && (
            <div>
              <h3>Currently Serving Military</h3>
              <p>
                Whether you're currently serving on Active Duty, in the Reserves or National Guard, we encourage you to
                apply to West Point. Please visit our website to learn about your eligibility requirements and next
                steps.
              </p>
              <a
                href="https://www.westpoint.edu/admissions/prospective-cadets/current-soldiers"
                target="_blank"
                rel="noreferrer"
              >
                See our admissions process for current soldiers on our website
              </a>
            </div>
          )}
        </div>
      </div>
    )
  }
  return (
    <div id="connect">
      <code>JSObject not setup correctly</code>
    </div>
  )
}

export default JSObject
